import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Container, Row } from 'reactstrap';
import {
  Layout,
  BlogPosts,
} from '../../components';

const BlogPage = ({ data }) => (
  <Layout>
    <div />
    <Container className="section">
      <Row className="blog-homepage">
        <h1>Knapsac Blog</h1>
        <h2>Discussing all things budgeting</h2>
        <h3>{data.posts.edges.length} Posts</h3>
        <BlogPosts
          posts={data.posts}
        />
      </Row>
    </Container>
  </Layout>
);

BlogPage.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired,
  }).isRequired,
};

export default BlogPage;

export const query = graphql`
  query BlogPageQuery {
    posts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
         regex: "/\\/content\\/blog-posts\\//"
        }
      }
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
    ) {
      edges {
        ...BlogPostExcerpt
      }
    }
  }
`;
